<template>
  <div class="zoey-header">
    <NuxtLink v-if="!isTop"  to="/"><img src="~/assets/img/common/LOGOnB.svg"></NuxtLink>
    <div v-else></div>
    <a @click="openMenu">
      <img v-if="isTop" src="~/assets/img/common/btn-menu-white.svg">
      <img v-else src="~/assets/img/common/btn-menu-black.svg"/>
    </a>
  </div>
</template>

<script setup>
import { ref,provide } from 'vue'
const props = defineProps({
  isTop:{
    type:Boolean,
    default:false,
  }
})
const emits = defineEmits()
function openMenu(){
  emits('openMenu');
}


</script>
<style lang="scss" scoped>
.zoey{
  &-header{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
  }
}
</style>
